<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="editButton"
  >
    <v-text-field v-model="input.name" label="Название" />
    <v-text-field v-model="input.nameKz" label="Название на казахском" />
    <p class="mt-4">Описание на русском</p>
    <tinymce
      id="d1"
      v-model="input.description"
      :toolbar1="'formatselect'"
      :other_options="options"
    ></tinymce>
    <p class="mt-4">Описание на казахском</p>
    <tinymce
      id="d2"
      v-model="input.descriptionKz"
      :toolbar1="'formatselect'"
      :other_options="options"
    ></tinymce>
    <div v-for="(item, i) in input.parents" :key="item + i">
      <v-autocomplete
        v-model="input.parents[i]"
        :items="[{ id: 0, name: 'Нет категории' }].concat(CATEGORY.allCategory)"
        item-text="name"
        item-value="id"
        no-data-text="Начните поиск"
        class="mt-3"
        label="Род. категория"
        clearable
      />
      <v-text-field
        v-model="input.orderOnPage[i]"
        label="Номер расположения на каталоге"
      ></v-text-field>
      <div class="parent_category">
        <v-btn color="error" @click="deleteParentCategory(i)">Удалить</v-btn>
      </div>
    </div>

    <v-btn color="primary" class="mt-2" @click="addParentCategory()"
      >добавить родительскую категорию</v-btn
    >
    <v-row>
      <v-col cols="6">
        <v-checkbox
          v-model="input.hidden"
          label="Скрыть категорию"
        ></v-checkbox>
        <v-checkbox
          v-model="input.deleted"
          label="Удаленная категория"
        ></v-checkbox>
        <v-checkbox
          v-model="input.promo"
          label="Промо категория"
          @change="deleteDepartment($event)"
        ></v-checkbox>
        <v-autocomplete
          v-if="input.promo"
          v-model="input.departmentId"
          :items="USER.userInfo.departments"
          item-text="name"
          item-value="id"
          no-data-text="Начните поиск"
          class="mt-3"
          label="Филиалы"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <input
          ref="imgInput"
          type="file"
          hidden
          accept="image/*"
          @change="setImage($event)"
        />
        <v-img
          class="img"
          align-self="'center'"
          max-height="300"
          max-width="350"
          contain
          lazy-src="@/assets/placeholder.png"
          :src="input.image"
          @click="$refs.imgInput.click()"
        />
      </v-col>
    </v-row>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import category from "@/model/category";
import showMessage from "@/Functions/message";

export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {},
      options: {
        language: "ru",
      },
      file: null,
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/STATE",
      USER: "User/STATE",
    }),
  },
  watch: {
    file: {
      handler(val) {
        if (val == null || val.type.substr(0, 5) !== "image") {
          this.file = null;
        }
      },
      deep: true,
    },
  },
  async created() {
    let body = {};
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      body = await this.GET_CATEGORY(this.$route.params.id);
    } else {
      // нужна минимальная задержка иначе ошибка в консоли
      await new Promise((res) => {
        setTimeout(() => {
          res();
        }, 100);
      });
    }
    let model = new category();
    this.input = model.setInput(this.mode, body);
    await this.setEmptys();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      ADD_NEW_CATEGORY: "Category/ADD_NEW_CATEGORY",
      GET_CATEGORY: "Category/GET_CATEGORY",
      UPDATE_CATEGORY: "Category/UPDATE_CATEGORY",
      GET_ALL_CATEGORY: "Category/GET_ALL_CATEGORY",
      EDIT_IMAGE: "Category/EDIT_IMAGE",
    }),
    async setEmptys() {
      if (this.CATEGORY.allCategory.length === 0) {
        await this.GET_ALL_CATEGORY(0);
      }
    },
    async addButton() {
      if (!this.input.name || !this.input.nameKz) {
        showMessage("Введите названия категории");
        return;
      }
      this.loading = true;
      delete this.input.id;
      this.input.parentMap = this.input.parents.reduce((accum, item, i) => {
        accum[item] = this.input.orderOnPage[i];
        return accum;
      }, {});
      let response = await this.ADD_NEW_CATEGORY(this.input);
      if (response.type === "error") {
        showMessage(response.text);
      } else {
        if (this.file) {
          let form = new FormData();
          form.append("file", this.file);
          await this.EDIT_IMAGE({
            categoryId: response.id,
            input: form,
          });
        }
        this.$router.push("/category");
        showMessage();
      }
      this.loading = false;
    },
    async editButton() {
      if (!this.input.name || !this.input.nameKz) {
        showMessage("Введите названия категории");
        return;
      }
      this.loading = true;
      this.input.parentMap = this.input.parents.reduce((accum, item, i) => {
        accum[item] = this.input.orderOnPage[i];
        return accum;
      }, {});
      let response = await this.UPDATE_CATEGORY(this.input);
      if (response.type === "error") {
        showMessage(response.data.message);
      } else {
        if (this.file) {
          let form = new FormData();
          form.append("file", this.file);
          await this.EDIT_IMAGE({
            categoryId: this.$route.params.id,
            input: form,
          });
        }
        this.$router.push("/category");
        showMessage();
      }
      this.loading = false;
    },
    setImage(val) {
      if (val.target.files[0].size > 1000000) {
        showMessage("Размер файла превышает 1мб");
      } else {
        this.file = val.target.files[0];
        this.input.image = URL.createObjectURL(this.file);
      }
    },
    addParentCategory() {
      this.input.parents.push(0);
      this.input.orderOnPage.push(0);
    },
    deleteParentCategory(categoryIndex) {
      this.input.parents.splice(categoryIndex, 1);
      this.input.orderOnPage.splice(categoryIndex, 1);
    },
    // если убрали статус промо, то также удаляем филиал
    deleteDepartment(promo) {
      if (!promo) {
        this.input.departmentId = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.img {
  cursor: pointer;
}
.parent_category {
  display: flex;
  width: 100%;
  justify-content: right;
  padding-bottom: 5px;
}
</style>
